import React, { Suspense } from "react";
import { observer } from "mobx-react";
import { styled } from "../../stitches.config";

/* webpackChunkName: "redesign__coponents__block5List" */
import BFivePlusList from "../../redesign/components/block5List";

const ErrorBoundary = React.lazy(() => import(/* webpackChunkName: "parts__ErrorBoundary" */"../Article/Parts/ErrorBoundary"));

//Lazy load
const blockTypes = {
  //v1
  "header": React.lazy(() => import(/* webpackChunkName: "block__Header" */"./block/Header")),
  "bloger": React.lazy(() => import(/* webpackChunkName: "block__Bloger" */"./block/Bloger")),
  "one": React.lazy(() => import(/* webpackChunkName: "block__One" */"./block/One")),
  "two": React.lazy(() => import(/* webpackChunkName: "block__Two" */"./block/Two")),
  "three": React.lazy(() => import(/* webpackChunkName: "block__Three" */"./block/Three")),
  "four": React.lazy(() => import(/* webpackChunkName: "block__Four" */"./block/Four")),
  "mt-standard": React.lazy(() => import(/* webpackChunkName: "block__Standard" */"./block/Standard")),
  "one-image": React.lazy(() => import(/* webpackChunkName: "block__OneImage" */"./block/OneImage")),
  "video": React.lazy(() => import(/* webpackChunkName: "block__Video" */"./block/Video")),
  "special1": React.lazy(() => import(/* webpackChunkName: "block__Special1" */"./block/Special1")),
  "special3": React.lazy(() => import(/* webpackChunkName: "block__Special3" */"./block/Special3")),
  "special4": React.lazy(() => import(/* webpackChunkName: "block__Special4" */"./block/Special4")),
  "trudat-light-tough": React.lazy(() => import(/* webpackChunkName: "block__TrudatLightTough" */"./block/TrudatLightTough")),
  "trudat-one-two": React.lazy(() => import(/* webpackChunkName: "block__TrudatOneTwo" */"./block/TrudatOneTwo")),
  "trudat-announcement": React.lazy(() => import(/* webpackChunkName: "block__TrudatAnnouncement" */"./block/TrudatAnnouncement")),
  "trudat-quote": React.lazy(() => import(/* webpackChunkName: "block__TrudatQuote" */"./block/TrudatQuote")),
  "trudat-month": React.lazy(() => import(/* webpackChunkName: "block__TrudatMonth" */"./block/TrudatMonth")),
  "trudat-two": React.lazy(() => import(/* webpackChunkName: "block__TrudatTwo" */"./block/TrudatTwo")),
  "trudat-three": React.lazy(() => import(/* webpackChunkName: "block__TrudatThree" */"./block/TrudatThree")),

  //redesign A
  "a-two": React.lazy(() => import(/* webpackChunkName: "block__ATwo" */"./block/ATwo")),
  "a-three": React.lazy(() => import(/* webpackChunkName: "block__AThree" */"./block/AThree")),
  "a-four": React.lazy(() => import(/* webpackChunkName: "block__AFour" */"./block/AFour")),
  "a-two-high": React.lazy(() => import(/* webpackChunkName: "block__ATwoHigh" */"./block/ATwoHigh")),
  "a-three-high": React.lazy(() => import(/* webpackChunkName: "block__AThreeHigh" */"./block/AThreeHigh")),
  "a-three-high-auto": React.lazy(() => import(/* webpackChunkName: "block__AThreeHighAuto" */"./block/AThreeHighAuto")),
  "a-four-high": React.lazy(() => import(/* webpackChunkName: "block__AFourHigh" */"./block/AFourHigh")),
  "a-one": React.lazy(() => import(/* webpackChunkName: "block__AOne" */"./block/AOne")),
  "a-special": React.lazy(() => import(/* webpackChunkName: "block__ASpecial5" */"./block/ASpecial")),
  "a-special1": React.lazy(() => import(/* webpackChunkName: "block__ASpecial1" */"./block/ASpecial1")),
  "a-special2": React.lazy(() => import(/* webpackChunkName: "block__ASpecial2" */"./block/ASpecial2")),
  "a-special3": React.lazy(() => import(/* webpackChunkName: "block__ASpecial3" */"./block/ASpecial3")),
  "a-special4": React.lazy(() => import(/* webpackChunkName: "block__ASpecial4" */"./block/ASpecial4")),
  "a-special5": React.lazy(() => import(/* webpackChunkName: "block__ASpecial5" */"./block/ASpecial5")),
  "a-countdownthree": React.lazy(() => import(/* webpackChunkName: "block__ACountdownThree" */"./block/ACountdownThree")),
  "a-countdownsamsung": React.lazy(() => import(/* webpackChunkName: "block__ACountdownSamsung" */"./block/ACountdownSamsung")),
  "a-specialdayscounter": React.lazy(() => import(/* webpackChunkName: "block__ASpecialDaysCounter" */"./block/ASpecialDaysCounter")),
  //Hardcover
  "hardcover": React.lazy(() => import(/* webpackChunkName: "block__AHardcoverTile" */"./block/AHardcoverTile")),

  //Brid
  "brid-one": React.lazy(() => import(/* webpackChunkName: "block__ABridOne" */"./block/ABridOne")),
  "brid-one-finished": React.lazy(() => import(/* webpackChunkName: "block__ABridOneFinished" */"./block/ABridOneFinished")),
  "brid-three-narrow": React.lazy(() => import(/* webpackChunkName: "block__ABridThreeNarrow" */"./block/ABridThreeNarrow")),
  "brid-timer-three": React.lazy(() => import(/* webpackChunkName: "block__ABridTimerThree" */"./block/ABridTimerThree")),
  "brid-timer-three-novote": React.lazy(() => import(/* webpackChunkName: "block__ABridTimerThreeNoVote" */"./block/ABridTimerThreeNoVote")),
  "brid-three-timer": React.lazy(() => import(/* webpackChunkName: "block__ABridThreeTimer" */"./block/ABridThreeTimer")),
  "brid-compare": React.lazy(() => import(/* webpackChunkName: "block__ABridCompare" */"./block/ABridCompare")),
  "brid-compare-with-link": React.lazy(() => import(/* webpackChunkName: "block__ABridCompareWithLink" */"./block/ABridCompareWithLink")),
  "brid-universal-compare": React.lazy(() => import(/* webpackChunkName: "block__BridUniversalCompare" */"./block/BridUniversalCompare")),
  "brid-header": React.lazy(() => import(/* webpackChunkName: "block__ABridHeader" */"./block/ABridHeader")),
  "brid-primary-header": React.lazy(() => import(/* webpackChunkName: "redesign__components__BridPrimaryHeader" */"../../redesign/components/BridPrimaryHeader")),
  "brid-submit-project": React.lazy(() => import(/* webpackChunkName: "redesign__components__BridSubmitProject" */"../../redesign/components/BridSubmitProject")),

  //Redesign B
  "b-headline": React.lazy(() => import(/* webpackChunkName: "redesign__atoms__BHeading" */"../../redesign/atoms/BHeading")),
  "b-three-x-three": React.lazy(() => import(/* webpackChunkName: "redesign__atoms__BBlock3x3" */"../../redesign/BBlock3x3")),
  "b-one-plus-list": React.lazy(() => import(/* webpackChunkName: "redesign__components__block1List" */"../../redesign/components/block1List")),
  "b-five-plus-list": BFivePlusList,//React.lazy(()=>import(/* webpackChunkName: "redesign__coponents__block5List" */"../../redesign/components/block5List")),
  "b-six-plus-list": React.lazy(() => import(/* webpackChunkName: "redesign__components__block6List" */"../../redesign/components/block6List")),
  "b-category": React.lazy(() => import(/* webpackChunkName: "redesign__components__BCategoryt" */"./block/BCategory")),
  "b-category-6": React.lazy(() => import(/* webpackChunkName: "redesign__components__BCategory6" */"./block/BCategory6")),
  "b-separator": React.lazy(() => import(/* webpackChunkName: "redesign__atoms__Separator" */"../../redesign/atoms/Separator")),
  "b-one": React.lazy(() => import(/* webpackChunkName: "block__BOne" */"./block/BOne")),
  "b-one-super": React.lazy(() => import(/* webpackChunkName: "block__BOneSuper" */"./block/BOneSuper")),
  "b-two-high": React.lazy(() => import(/* webpackChunkName: "block__BTwoHigh" */"./block/BTwoHigh")),
  "b-high-4": React.lazy(() => import(/* webpackChunkName: "block__BHigh4" */"./block/BHigh4")),
  "b-1d-2m": React.lazy(() => import(/* webpackChunkName: "block__B1d2m" */"./block/B1d2m")),
  "b-1d-5m": React.lazy(() => import(/* webpackChunkName: "block__B1d5m" */"./block/B1d5m")),
  "b-2d-2m": React.lazy(() => import(/* webpackChunkName: "block__B2d2m" */"./block/B2d2m")),
  "b-columnists": React.lazy(() => import(/* webpackChunkName: "block__BColumnists" */"./block/BColumnists")),
  "b-our-podcasts": React.lazy(() => import(/* webpackChunkName: "block__BOurPodcasts" */"./block/BOurPodcasts")),
  "b-for-you": React.lazy(() => import(/* webpackChunkName: "redesign__ForYou" */"./../../redesign/ForYou")),
  "b-collection-tile": React.lazy(() => import(/* webpackChunkName: "block__BCollectionTile" */"./block/BCollectionTile")),
  "b-hot-news": React.lazy(() => import(/* webpackChunkName: "block__BHotNews" */"./block/BHotNews")),
  "b-hot-news-super": React.lazy(() => import(/* webpackChunkName: "block__BHotNewsSuper" */"./block/BHotNewsSuper")),
  "b-hot-news-bar": React.lazy(() => import(/* webpackChunkName: "block__BHotNewsBar" */"./block/BHotNewsBar")),
  "b-embed-carousel": React.lazy(() => import(/* webpackChunkName: "block__BEmbedCarousel" */"./block/BEmbedCarousel")),
  "b-onnetwork-widget": React.lazy(() => import(/* webpackChunkName: "block__BOnnetworkWidget" */"./block/BOnnetworkWidget")),

  //akcje specjalne
  "a-special-lg": React.lazy(() => import(/* webpackChunkName: "block__ASpecialLG" */"./block/ASpecialLG")),
  "a-special-carrefour": React.lazy(() => import(/* webpackChunkName: "block__ASpecialCarrefour" */"./block/ASpecialCarrefour")),
  "a-special-goodyear": React.lazy(() => import(/* webpackChunkName: "block__ASpecialGoodyear" */"./block/ASpecialGoodyear")),
  "a-special-santander": React.lazy(() => import(/* webpackChunkName: "block__ASpecialSantander" */"./block/ASpecialSantander")),
  "a-mini-site": React.lazy(() => import(/* webpackChunkName: "block__AMiniSite" */"./block/AMiniSite")),
  "a-mini-site-2": React.lazy(() => import(/* webpackChunkName: "block__AMiniSite2" */"./block/AMiniSite2")),
  "a-mini-site-3": React.lazy(() => import(/* webpackChunkName: "block__AMiniSite2" */"./block/AMiniSite3")),
  "a-mini-site-4": React.lazy(() => import(/* webpackChunkName: "block__AMiniSite4" */"./block/AMiniSite4")),
  "a-mini-site-5": React.lazy(() => import(/* webpackChunkName: "block__AMiniSite4" */"./block/AMiniSite5")),
  "a-mini-site-6": React.lazy(() => import(/* webpackChunkName: "block__AMiniSite6" */"./block/AMiniSite6")),
  "a-mini-site-7": React.lazy(() => import(/* webpackChunkName: "block__AMiniSite7" */"./block/AMiniSite7")),
  "a-mini-site-8": React.lazy(() => import(/* webpackChunkName: "block__AMiniSite8" */"./block/AMiniSite8")),
  "a-mini-site-9": React.lazy(() => import(/* webpackChunkName: "block__AMiniSite9" */"./block/AMiniSite9")),
  "a-incredible-inspirations": React.lazy(() => import(/* webpackChunkName: "block__BIncredibleInspirations" */"./block/BIncredibleInspirations")),
  "a-special-football-scores": React.lazy(() => import(/* webpackChunkName: "block__ASpecialFootballScores" */"./block/ASpecialFootballScores")),

  // mamadu kategoria ciąża 
  "pregnancy-calendar": React.lazy(() => import(/* webpackChunkName: "block__PregnancyCalendar" */"./block/PregnancyCalendar")),
  "category-trimester": React.lazy(() => import(/* webpackChunkName: "redesign__components__CategoryTrimester" */"./block/CategoryTrimester")),
  "category-trimester-2": React.lazy(() => import(/* webpackChunkName: "redesign__components__CategoryTrimester2" */"./block/CategoryTrimester2")),
  "category-trimester-3": React.lazy(() => import(/* webpackChunkName: "redesign__components__CategoryTrimester3" */"./block/CategoryTrimester3")),
};

const BlockPlaceholder = styled("div", {
  "height": "300px"
});


@observer
export default class Block extends React.Component {

  static OBSERVER = new IntersectionObserver(entries => {
    entries.forEach(({ isIntersecting, target: element }) => {
      if (isIntersecting)
        element.dispatchEvent(new CustomEvent('intersecting'))
    });
  }, {
    rootMargin: "400px 0px 400px 0px"
  });

  constructor(props) {
    super(props);
    this.observer = null;
    this.state = {
      show: !!props?.notLazy
    };
    this.container = null;
    this.handleContainerIntersection = function () {
      this.setState({
        show: true
      });
    }.bind(this);
  }

  componentDidMount() {
    if (!this.container)
      return;


    if (!this.state.show && !window.SSR) {
      Block.OBSERVER.observe(this.container);
      this.container.addEventListener('intersecting', this.handleContainerIntersection);
    }
  }

  componentWillUnmount() {
    if (!this.container || window.SSR)
      return;

    this.container.removeEventListener('intersecting', this.handleContainerIntersection);
    Block.OBSERVER.unobserve(this.container);
  }

  render() {
    var block = null,
      settings = this.props.settings ? this.props.settings : {},
      blockSettings = settings.settings ? settings.settings : {},
      params = {
        settings: settings,
        blockId: this.props.id,
        blockSettings: blockSettings,
        blockGroupHasBackground: this.props.blockGroupHasBackground || false,
        blockColorBackground: this.props.blockColorBackground,
        onShowBoxEditor: this.handleShowBoxEditor,
        onReplaceBlocks: this.props.onReplaceBlocks,
        onRegisterBox: this.props.onRegisterBox,
        media_domain: this.props.media_domain,
        isDarkMode: this.props.isDarkMode,
      };

    params.block_type = params.settings.type;

    let sequences = {};
    Object.keys(blockTypes).map(function (key, index) {
      sequences[key] = 0;
    });

    block = blockTypes[params.settings.type];

    if (block === undefined) {
      return null;
    }

    //Add additional params to block
    switch (params.settings.type) {
      case "b-one":
        params = { ...params, type: "b-one" };
        break;
      case "b-one-super":
        params = { ...params, type: "b-one" };
        break;
      case "b-high-4":
        params = { ...params, isDarkMode: this.props.isDarkMode };
        break;
      default:
        break;
    }

    const blockComponent = React.createElement(block, params, null);
    const placeholder = <BlockPlaceholder />

    return (
      <div
        data-section-id={this.props.id}
        data-type={this.props.settings.type}
        data-merge={blockSettings.merge === "1" ? 1 : 0}
        id={'block--' + params.settings.type + '--' + (++sequences[params.settings.type])}
        ref={div => {
          this.container = div;
        }}
      >
        {!this.state.show && placeholder}
        {this.state.show && <Suspense fallback={null}>
          <ErrorBoundary>
            {blockComponent}
          </ErrorBoundary>
        </Suspense>}
        {this.state.show && blockSettings.placement && blockSettings.placement.length > 3 ? (
          <div dangerouslySetInnerHTML={{ __html: blockSettings.placement }} />
        ) : null}
      </div>
    );
  }
}
