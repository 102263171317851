import React, { useState, useRef } from "react";
import { styled } from "../../stitches.config";
import { withThemeContext } from "./StitchesThemeProvider";
import Link from "../../components/Link";
// import List from '../../components/composition-components/box/List';
import { ReactComponent as LiveIcon } from "../../assets/img/live.svg?react";
import Box from "../../components/composition-components/Box";

interface IPost {
  background: string;
  background_image: string;
  category: string;
  label: string;
  link: string;
  lockAt: number;
  locked: string;
  media: any;
  partner: string;
  player: string;
  search: string;
  show_author: boolean;
  show_links?: string;
  show_partner_logo?: boolean;
  title_normal: string;
}
interface IList {
  label: string;
  postCategoryId: number | null;
  posts: Array<{
    link: string;
    title: string;
    labels?: string[];
    time: string;
    label?: string;
  }>;
}

interface INewsListProps {
  lists: IList[];
  isDarkMode?: boolean;
  style?: string;
  tbRegion?: string;
  smallList?: boolean;
  settings?: any[];
}

const NewsList: React.FC<INewsListProps> = ({ isDarkMode, style, lists, tbRegion, smallList, settings, ...props }) => {

  const [activeTab, setActiveTab] = useState<string>(
    `${lists ? lists[0].label : ""}`
  );
  const [showMore, setShowMore] = useState(false);

  const handleActiveTab = (list: IList) => {
    setActiveTab(list.label);
  };

  const bg = (isDarkMode ? '19,18,18' : '255,255,255');
  const hasBackground = style === "with-background" ? true : false;
  const moreThanOneButton = lists.length > 1

  return (<>
    <StyledList>
      <StyledListButtons>
        {lists?.map((list) => (
          <Button
            onClick={() => handleActiveTab(list)}
            active={activeTab === list.label}
            darkMode={isDarkMode}
            className={moreThanOneButton ? "more-than-one-button" : ""}
            data-analytics-name={list.label === "Extra" ? "btn-extra-sg" : null}
          >
            {" "}
            {list.label} {list.label === "Najnowsze" && <LiveIcon />}
          </Button>
        ))}
      </StyledListButtons>
      <StyledListItems
        smallList={smallList}
        className={showMore ? 'show-more-articles' : 'hide-more-articles'}
        css={{
          '&:after': {
            background: `linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(${bg},1) 100%);`,
          },
        }}
      >
        <div data-tb-region={tbRegion ? tbRegion + '_' + activeTab : undefined}>
          {lists &&
            lists
              .find((item) => item.label === activeTab)
              ?.posts.slice(0, 5).map((post, ix) => (
                <div data-tb-region-item>
                  <Link className={hasBackground ? "style--with-background" : ""} to={post.link} data-analytics-name={post.time ? "btn-najnowsze-" + ix : "btn-extra-" + ix}>
                    <TextAligner useAligner={post.labels && post.labels.length > 0}>
                      {post.time ? /*(<Time>{post.time}</Time>)*/ null : <Dot />}
                      {post.label && <span className="urgent">{post.label}</span>}
                      {post.labels &&
                        post.labels.map((label) => (
                          <span className="urgent">{label}</span>
                        ))}
                    </TextAligner>
                    <span className="title">{post.title}</span>
                  </Link>
                </div>
              ))}
          {/* adslot breakpoint */}
          <Box {...props}
            id="b2"
            type="BNewsListAd"
            settings={settings}
            activeTab={activeTab}
          />
          {/* 2 part of posts */}
          {lists &&
            lists
              .find((item) => item.label === activeTab)
              ?.posts.slice(6, 14).map((post, ix) => (
                <div data-tb-region-item>
                  <Link className={hasBackground ? "style--with-background" : ""} to={post.link} data-analytics-name={post.time ? "btn-najnowsze-" + ix : "btn-extra-" + ix}>
                    <TextAligner useAligner={post.labels && post.labels.length > 0}>
                      {post.time ? /*(<Time>{post.time}</Time>)*/ null : <Dot />}
                      {post.label && <span className="urgent">{post.label}</span>}
                      {post.labels &&
                        post.labels.map((label) => (
                          <span className="urgent">{label}</span>
                        ))}
                    </TextAligner>
                    <span className="title">{post.title}</span>
                  </Link>
                </div>
              ))}
        </div>
      </StyledListItems>
    </StyledList>
    {!showMore && <LinkMoreMobile onClick={() => setShowMore(true)}>
      Więcej
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.890625 5.01223L2.022 3.88086L7.99773 9.8566L13.9735 3.88086L15.1048 5.01223L7.99773 12.1193L0.890625 5.01223ZM13.9733 4.16387L14.8217 5.01227L7.9976 11.8364L7.99773 11.8365L14.822 5.01223L13.9735 4.1637L13.9733 4.16387ZM2.02183 4.16386L7.99744 10.1395L7.9976 10.1393L2.022 4.1637L2.02183 4.16386Z" />
      </svg>
    </LinkMoreMobile>}
  </>
  );
};

const Dot = styled('div', {
  width: '6px',
  height: '6px',
  marginRight: '5px',
  marginBottom: '1px',
  borderRadius: '50%',
  content: "",
  display: 'inline-block',
  backgroundColor: "$secondary",
  position: 'relative',
});

const Button = styled("button", {
  fontWeight: "600",
  variants: {
    darkMode: {
      true: {
        "&:hover": {
          background: "#1B1B1B",
        },
      },
      false: {
        "&:hover": {
          background: "#F5F5F5",
        },
      },
    },
    active: {
      true: {
        background: "$primary !important",
        color: "#fff !important",
        "&:hover": {
          background: "$primary !important",
        },
      },
      false: {
        background: "transparent",
      },
    },
  },
});

const StyledList = styled("div", {
  position: "relative",
  width: "100%",
});
const Time = styled("span", {
  color: "$secondary",
  fontWeight: "$8",
  fontSize: "14px",
  lineHeight: "17.5px",
  display: "inline-block",
});

const StyledListButtons = styled("div", {
  marginBottom: "6px",
  border: "1px solid $grayscale25",
  padding: "2px",
  display: "flex",
  justifyContent: "space-between",
  "& button": {
    width: "100%",
    margin: "0",
    borderWidth: "0px",
    background: "$grayscale0",
    color: "$grayscale75",
    padding: "6px 0",
    borderRadius: "1px",
    cursor: "pointer",
  },
  ".more-than-one-button": {
    width: "calc(50% - 1px)",
  },
  "& .active": {
    background: "$primary",
    color: "$grayscale0",

    // '&:hover': {
    //     background: "$primary",
    // },
    "& span": {
      display: "inline-block",
      width: "12px",
      height: "12px",
      content: " ",
      // background: `url(${LiveIcon})`,
      backgroundSize: "cover",
    },
  },
});

const StyledListItems = styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  position: "relative",

  "> div": {
    overflow: "hidden",
    "@bp4": {
      overflowY: "scroll",
    }
  },

  "&.hide-more-articles": {
    overflow: "hidden",
    maxHeight: "500px",
    /*"&:after": {
      pointerEvents: 'none',
      zIndex: '3',
      content: "",
      display: 'block',
      position: 'absolute',
      bottom: "0",
      left: 0,
      right: 0,
      height: '100px',
      // background: "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);",
      // background: "linear-gradient(180deg, rgba(19, 18, 18, 0%) 0%, rgba(19, 18, 18, 0) 100%)",
    },
    */

    "@bp4": {
      height: '600px',
      maxHeight: "600px",
    },
  },

  variants: {
    smallList: {
      true: {
        "&.hide-more-articles": {
          "@bp4": {
            height: '500px',
            maxHeight: "500px",
          }
        },
      },
      false: {
      },
    },
  },


  "& a": {
    display: "inline-block",
    color: "$grayscale100",
    textDecoration: "none",
    padding: "14px 0",
    borderBottom: "1px solid $grayscale10",
    position: "relative",

    "&:hover, &:focus, &:active": {
      color: "$grayscale100",
    },

    "@bp4": {
      padding: "12px 0",
    },

    "& .time": {
      color: "$primary",
      fontWeight: "$8",
    },

    "& .urgent": {
      background: "$primary",
      color: "$grayscale0",
      fontWeight: "$8",
      padding: "2px 5px",
      fontSize: "10px",
      lineHeight: "12px",
      marginLeft: "0px",
      marginRight: "5px",
      letterSpacing: "0.04em",
      display: "inline-block",
    },

    "& .title": {
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: "600",
      marginLeft: "0px",
      "&:hover": {
        textDecoration: "underline",
      }
    },

    "&.style--with-background": {
      color: "$grayscale0Fixed",
    }
  },
});

const TextAligner = styled("span", {
  variants: {
    useAligner: {
      true: {
        display: "inline-flex",
        alignItems: "center",
      },
      false: {
        display: "inline",
      },
    },
  }
});

const LinkMoreMobile = styled("a", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: 600,
  fontSize: 18,
  lineHeight: 24,
  color: "$primary",
  maxHeight: 24,
  margin: "24px 0 0 0",

  "&:active, &:hover": {
    textDecoration: "none",
    color: "$primary",
  },

  "& svg": {
    marginLeft: "8px",
    "path": {
      fill: "$primary"
    },
  },

  "@bp4": {
    display: "none",
  },
});

export default withThemeContext(NewsList);
